<template>
  <div class="floor-plans">
    <div class="card sm-p-3">
      <div>
        <h3 class="d-inline-block">Unit Types</h3>
      </div>
      <div class="row justify-content-end">
        <button v-if="canAdd" class="btn main" @click="addFloorPlan">
          <i class="fas fa-plus"></i
          ><span class="d-none sm-d-inline-block ml-1">Add</span>
        </button>
        <button
          :class="{ active: isFilterActive }"
          class="btn toggle ml-1"
          @click="filterToggle"
        >
          <i class="fas fa-filter"></i>
        </button>
      </div>

      <!-- Filter -->
      <filter-panel
        v-model="isFilterActive"
        bordered
        class="filter my-2"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 px-1 mb-2"
            label="Name"
            name="filterFloorPlanName"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>

      <!-- Content -->
      <div class="py-2">
        <!-- Spinner -->
        <div
          v-if="isLoading"
          class="spinner-container d-flex justify-content-center align-items-center"
        >
          <spinner></spinner>
        </div>
        <!-- Content -->
        <div v-else>
          <no-data
            v-if="floorPlans.length < 1"
            message="Oops, there is no floor plan available for now."
          ></no-data>

          <div v-else>
            <!-- floor plan cards -->
            <div class="row justify-content-start">
              <floor-plan-card
                v-for="floorPlan in floorPlans"
                :key="floorPlan.id"
                :floorPlan="floorPlan"
                class="col-12 sm-col-6 md-col-4 lg-col-3"
                :canEdit="canEdit"
                :canDelete="canDelete"
                @delete="deleteFloorPlan"
                @edit="editFloorPlan"
                @toDetail="toFloorPlanDetail"
                @addNewUnit="addNewUnit"
                @allocateAgents="allocateAgents"
              >
                <template #action>
                  <slot
                    name="floor-plan-card-action"
                    :floorPlan="floorPlan"
                  ></slot>
                </template>
              </floor-plan-card>
            </div>
          </div>
        </div>
        <!-- pagination -->
        <fd-page
          v-show="floorPlans.length > 0"
          class="text-center pt-2"
          :value="paginationParams.page"
          :total-row="pagination.total"
          :perPage="paginationParams.perPage"
          @page-change="pageChange"
          @input="
            updatePaginationParams(
              paginationParams.page,
              paginationParams.perPage
            )
          "
        ></fd-page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    FloorPlanCard: () =>
      import("@/modules/Project/components/FloorPlan/FloorPlanCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    floorPlans: {
      type: Array,
      default: () => []
    },
    getFloorPlans: {
      type: Function,
      required: true
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    paginationParams: {
      type: Object,
      default: () => ({
        page: 1,
        perPage: 4
      })
    },
    propertyTypeId: {
      type: [Number, String]
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      // Filter Related
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toFloorPlanDetail(floorPlanId) {
      this.$emit("toDetail", floorPlanId);
    },
    addFloorPlan() {
      this.$emit("addFloorPlan");
    },
    deleteFloorPlan(id) {
      this.$emit("deleteFloorPlan", id);
    },
    editFloorPlan(id) {
      this.$emit("editFloorPlan", id);
    },
    addNewUnit(floorPlanId) {
      this.$emit("addUnit", floorPlanId);
    },
    allocateAgents(floorPlanId) {
      this.$emit("allocateAgents", floorPlanId);
    },
    // ===================== Filter related methods =====================
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    async pageChange(info) {
      this.updateIsLoading(true);
      this.updatePaginationParams(
        info.pageNumber,
        this.paginationParams.perPage
      );

      await this.getFloorPlans();
      this.updateIsLoading(false);
    },
    filterData() {
      this.updateIsLoading(true);
      this.updatePaginationParams(1, this.paginationParams.perPage);
      this.getFloorPlans(this.filter).then(() => {
        this.updateIsLoading(false);
      });
    },
    clearFilter() {
      if (this.$isFilterEmpty(this.filter)) {
        return;
      } else {
        this.updateIsLoading(true);
        this.updatePaginationParams(1, this.paginationParams.perPage);
        this.filter = this._.mapValues(this.filter, () => null);
        this.getFloorPlans().then(() => {
          this.updateIsLoading(false);
        });
      }
    },
    updatePaginationParams(page, perPage) {
      this.$emit("update:paginationParams", { page: page, perPage: perPage });
    },
    updateIsLoading(bool) {
      this.$emit("update:isLoading", bool);
    }
  }
};
</script>

<style lang="scss">
.floor-plans {
  .spinner-container {
    min-height: 300px;
  }
}
</style>
